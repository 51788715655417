import React from "react";

function Error() {
  return (
    <body>
      <main>
        <h1>Error 404 not found</h1>
        <button>
          <a href="/">Take Me Home</a>
        </button>
      </main>
    </body>
  );
}

export default Error;
