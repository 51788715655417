import React from "react";
import { FaCodeBranch, FaHome } from "react-icons/fa";
import { AiFillCodepenCircle } from "react-icons/ai";
import { CgAwards } from "react-icons/cg";

export const logo = {
  id: 1,
  text: "muco",
  icon: <FaCodeBranch />,
};

export const links = [
  {
    id: 1,
    url: "/",
    text: "dashboard",
    icon: <FaHome />,
  },
  {
    id: 2,
    url: "/learning",
    text: "learning",
    icon: <AiFillCodepenCircle />,
  },
  {
    id: 3,
    url: "/rewards",
    text: "rewards",
    icon: <CgAwards />,
  },
  
];
