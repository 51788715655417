import React from "react";
import logo from "./data/images/logo.svg";
import { links } from "./data/links/sidebar-links";
import { useGlobalContext } from "./context";
import Logout from "./login/Logout";

const Sidebar = () => {
  const { isSidebarOpen, openSidebar, closeSidebar } = useGlobalContext();

  return (
    <aside
      onMouseEnter={() => openSidebar}
      onMouseLeave={() => closeSidebar}
      className={`sidebar ${isSidebarOpen ? "" : "show-sidebar"}`}
    >
      <div className="sidebar-header">
        <img src={logo} alt="" className="logo" />
        <h1 className="name">muco</h1>
      </div>
      <ul className="links">
        {links.map((link) => {
          const { id, url, text, icon } = link;
          return (
            <li key={id}>
              <a href={url}>
                {icon} <span className="link-text">{text}</span>
              </a>
            </li>
          );
        })}
      </ul>

      <Logout></Logout>
    </aside>
  );
};

export default Sidebar;
