import React from "react";
import { Link, useParams } from "react-router-dom";
import Theory from "./Theory";
import Example from "./Example";
import TryItYourself from "./TryItYourself";

import { learningContent } from "./../data/content/learning-content";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-tomorrow";
import { trackingPageGA } from "../Analytics/reactGA";

function Learning(props) {
  const { url } = props;
  let { topic } = useParams();
  let toPreviousModule = `1`;
  let toNextModule = `1`;

  var disableClick;
  var disable;

  trackingPageGA(window.location.pathname + window.location.search);

  const currentTopic = learningContent.find(
    (module) => module.topicId === topic
  );
  const {
    id,
    title,
    moduleColour,
    shortDescription,
    active,
    generalDescription,
    theoryTitle,
    theoryDescription,
    theoryKeywords,
    exampleTitle,
    exampleCode,
    exampleCodeVars,
    exampleCodeConcepts,
    exampleDescription,
    play,
    stop,
    tiyTitle,
    tiyInstructions,
  } = currentTopic;

  const nextTopic = learningContent.find(
    (module) => module.id === (id + 1)
  );

  if (nextTopic === undefined) {
    toNextModule = `${url}`;
  } else if (id >= 4) {
    toNextModule = `${url}`;
  } else {
    toNextModule = `${url}/${nextTopic.topicId}`;
  }

  const previousTopic = learningContent.find(
    (module) => module.id === (id - 1)
  );

  if (previousTopic === undefined) {
    toPreviousModule = `${url}`;
  } else {
    toPreviousModule = `${url}/${previousTopic.topicId}`;
  }

  const student = (JSON.parse(window.sessionStorage.getItem('token')).student);
  var [activeLevel, setActiveLevel] = React.useState(0);
  var [nextLevel, setNextLevel] = React.useState(0);
  function nextButton() {
    if (nextLevel <= activeLevel) {
      disableClick = "";
      disable = " ";
      // == is correct
    } else {
      disableClick = "true";
      disable = "w3-opacity";
    }
  }

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  React.useEffect(() => {
    setNextLevel(id + 1);
    setActiveLevel(student.activeLevelId - 1);
  }, [id, student.activeLevelId])

  nextButton();
  return (
    <>
      <div className="page-container">
        <main className="learning">
          <h1 style={{ color: moduleColour }}>{title}</h1>
          <p>{generalDescription}</p>

          <Theory
            key={id}
            title={theoryTitle}
            description={theoryDescription}
            keywords={theoryKeywords}
          />

          <Example
            title={exampleTitle}
            code={exampleCode}
            explanation={exampleDescription}
            play={play}
            stop={stop}
            codeVars={exampleCodeVars}
            codeConcepts={exampleCodeConcepts}
          />
          <TryItYourself id={id} title={tiyTitle} instructions={tiyInstructions} />

          <div className="row">
            <Link className="col" to={toPreviousModule} >
              <button id="navPreviousModule" >Previous Module</button>
            </Link>
            <Link className="col" to="/">
              <button id="navHome" >Home</button>
            </Link>
            <Link id="linkToNextModule" className={`col ${disable}`} to={toNextModule} style={disableClick ? { pointerEvents: "none" } : null}>
              <button id="navNextModule" >Next Module</button>
            </Link>
          </div>
        </main>
      </div>
    </>
  );
}

export default Learning;
