import React from "react";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";

import { Card } from "react-bootstrap";
import Learning from "./Learning";
import { learningContent } from "./../data/content/learning-content";

const LearningOverview = () => {
  let { path, url } = useRouteMatch();

  const student = JSON.parse(window.sessionStorage.getItem("token")).student;
  var activeLevel = student.activeLevelId - 1;
  var disableClick;
  var disable;

  return (
    <>
      <div className="page-container">
        <main className="main">
          <Switch>
            <Route exact path={path}>
              <h1>Overview of Learning Modules</h1>
              <div className="cards-container">
                {learningContent.map((learningItem) => {
                  const {
                    id,
                    topicId,
                    title,
                    icon,
                    moduleColour,
                    shortDescription,
                    active,
                  } = learningItem;

                  let maxLenDescription = 130;
                  let trimmedDescription = shortDescription.substr(
                    0,
                    maxLenDescription
                  );
                  trimmedDescription = trimmedDescription.substr(
                    0,
                    Math.min(
                      trimmedDescription.length,
                      trimmedDescription.lastIndexOf(" ")
                    )
                  );

                  //bad way to deactivate levels: direct link still works
                  if (id <= activeLevel) {
                    disableClick = "";
                    disable = " ";
                    // == is correct
                  } else {
                    disableClick = "true";
                    disable = "w3-opacity";
                  }

                  return (
                    //pointer events: none works only on card, not on titles/texts
                    <Card
                      className={`card-container ${disable}`}
                      style={{
                        position: "center",
                        height: "16rem",
                        width: "23rem",
                      }}
                      key={id}
                    >
                      <Link
                        to={`${url}/${topicId}`}
                        style={disableClick ? { pointerEvents: "none" } : null}
                      >
                        <div>
                          <div className="card-container__header">
                            <i
                              className="card-container__icon"
                              style={{ color: moduleColour }}
                            >
                              {icon}
                            </i>
                            <h4
                              className="card-container__title"
                              style={{ color: moduleColour }}
                            >
                              {title}
                            </h4>
                          </div>
                          <p
                            style={{ color: moduleColour }}
                          >{`${trimmedDescription}...`}</p>
                        </div>
                      </Link>
                    </Card>
                  );
                })}
              </div>
            </Route>
            <Route path={`${path}/:topic`}>
              <Learning url={url} />
            </Route>
          </Switch>
        </main>
      </div>
    </>
  );
};

export default LearningOverview;
