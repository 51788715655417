import React from "react";
import Highlighter from "react-highlight-words";
import CodeEditorExample from "./CodeEditorExample";

function Example(props) {
  const { title, code, play, stop, explanation, codeVars, codeConcepts } =
    props;
  const [explanationBefore, explanationAfter] = explanation;

  const explanationParagraphsBefore = explanationBefore
    .split("\n")
    .map((str, i) => (
      <h5 key={i} className="learning__example__text">
        <Highlighter
          highlightClassName="learning__example__text--highlighted"
          searchWords={codeConcepts}
          autoEscape={true}
          textToHighlight={str}
        />
      </h5>
    ));
  const explanationParagraphsAfter = explanationAfter
    .split("\n")
    .map((str, i) => (
      <h5 key={i} className="learning__example__text">
        <Highlighter
          highlightClassName="learning__example__text--highlighted"
          searchWords={codeConcepts}
          autoEscape={true}
          textToHighlight={str}
        />
      </h5>
    ));

  return (
    <>
      <div className="learning__example">
        <h2>{title} </h2>
        {explanationParagraphsBefore}
        <CodeEditorExample value={code} play={play} stop={stop} />
        {explanationParagraphsAfter}
      </div>
    </>
  );
}

export default Example;
