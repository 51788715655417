import React, { useState, useEffect, useRef } from "react";
import * as Tone from "tone";
import ReactAce from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-tomorrow";
import { connect } from "tone";

function CodeEditorExample(props) {
  const { value, play, stop } = props;

  // Declare the instruments for later use
  let loop;
  let bassSynth, cymbalSynth;
  let counter;

  // let time = Tone.now();

  // 5 - Data Types #####################################################################################################################
  function play5() {
    function setup() {
      counter = 0;

      bassSynth = new Tone.MembraneSynth().toDestination();
      cymbalSynth = new Tone.MetalSynth({
        frequency: 200,
        envelope: {
          attack: 0.001,
          decay: 1.4,
          release: 0.2,
        },
        harmonicity: 5.1,
        modulationIndex: 32,
        resonance: 400,
        octaves: 1.5,
      }).toDestination();

      loop = new Tone.Loop(dum, "16n");
      Tone.Transport.start();
      loop.start(0);
    }

    function dum(time) {
      if (counter % 4 === 0) {
        bassSynth.triggerAttackRelease("c1", "8n", time);
      }
      if (counter % 4 !== 1) {
        cymbalSynth.triggerAttackRelease("32n", time, 0.3);
      }
      counter = (counter + 1) % 16;
    }

    setup();
  }
  function stop5() {
    Tone.Transport.stop();
  }

  return (
    <>
      <ReactAce
        className="editor"
        placeholder="Write your code here"
        setOptions={{ useWorker: false }} // !! {useWorker: false} needs to stay! otherwise many strange DOM/webpacker errors
        minLines={7}
        maxLines={20}
        value={value}
        mode="javascript"
        theme="github"
        // setText={props.value}
        showPrintMargin={false}
        readOnly={true}
        // defaultValue={props.code}
        style={{
          height: "20vh",
          width: "70vw",
          borderRadius: "15px",
          marginBottom: "0.5rem",
        }}
        // set the state when the value changes
        // onChange={onChange}
      />

      <button id="play" onClick={play}>
        run
      </button>
      <button id="stop" onClick={stop}>
        stop
      </button>
    </>
  );
}
export default CodeEditorExample;
