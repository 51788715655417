import React from 'react';
import { Image } from "react-bootstrap";
import logo from "./../data/images/Group.png";
import Nav from 'react-bootstrap/Nav'

export default function PublicHeader() {

    return (
        <div className="header">
            <div className="w3-col s4 w3-left">
                <Image
                    src={logo}
                    style={{}}
                    rounded
                />
            </div>

            {/* <Nav className="w3-col s4 public-nav" activeKey="/home">
                <Nav.Item>
                    <Nav.Link href="/home">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="/login">Login</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="/sign-up">Sign up</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>
                        Disabled
                    </Nav.Link>
                </Nav.Item>
            </Nav> */}
        </div>
    );
}