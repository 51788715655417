import * as React from 'react';
import Quiz from 'react-quiz-component';
import { rewardContent } from "./../data/content/reward-content";
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from "react-ga";

function QuizCustom(props) {
  const { id, quizContent } = props;
  const [content, setContent] = React.useState({ ...quizContent });
  const token = (JSON.parse(window.sessionStorage.getItem('token')));
  var stringTokenToken = (JSON.stringify(token.token));

  var url = `https://muco.fh-campuswien.ac.at/api/private/students/` + token.student.studentId + `/activeLevel/`;

  // This part is for the next Button to be actice/inactive
  const student = (JSON.parse(window.sessionStorage.getItem('token')).student);
  var [activeLevel, setActiveLevel] = React.useState(0);
  var [nextLevel, setNextLevel] = React.useState(0);
  function nextButton() {
    if (nextLevel <= activeLevel) {
      //document.querySelector('#linkToNextModule').style.pointerEvents = true;
      //document.querySelector('#linkToNextModule').className = "col";
      // == is correct
    } else {
      document.querySelector('#linkToNextModule').style.pointerEvents = "none";
      document.querySelector('#linkToNextModule').className = "col w3-opacity";
    }
  }
  React.useEffect(() => {
    setNextLevel(id + 1);
    setActiveLevel(student.activeLevelId - 1);
  }, [id, student.activeLevelId])
  nextButton();


  //This is for the Elements to be refreshed when someone moves to a new level
  React.useEffect(() => {
    //setContent();

    if (document.getElementById("whenFalse")){ // Elements are used in dnd sorting component
    document.getElementById("whenFalse").hidden = true;
    document.getElementById("whenCorrect").hidden = true;}

  }, [quizContent])

  // calls the BE to update the Level of the Logged in User
  async function updateLevel() {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: `'${stringTokenToken}'` })
    };
    var nextLevel = token.student.activeLevelId + 1;
    const response = await fetch(url + nextLevel, requestOptions);
    const data = await response.json();
    token.student = data.student;
    var stringToken = (JSON.stringify(token));
    window.sessionStorage.setItem('token', stringToken);
  }

  // is called when the quiz is completed
  /* const onCompleteAction = (obj) => {
    if (id == activeLevel) {
      const finished = updateLevel();
      if (finished) {
        document.querySelector('#linkToNextModule').style.pointerEvents = "";
        document.querySelector('#linkToNextModule').className = "col";
      }
      const message = "Woohoo congratulations, you finished the Quiz and have unclocked the next level and earned the " + rewardContent[id].title + " Reward!";
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } */

  const renderCustomResultPage = (obj) => {
        console.log(obj);
        ReactGA.event({
            category: 'Quiz',
            action: 'Solved',
            label: obj.numberOfIncorrectAnswers
        })
        return (
            <div>
                <h3 style={{ color: "white", marginLeft: "0px", paddingLeft: "0px" }}> Congrats! You are perfect! </h3>
            </div>
        )
    }

    function onQuizClick() {
        ReactGA.event({
            category: 'Quiz',
            action: 'Loaded'
        });
    }

  return (
    <>
      <div id="quiz" className="learning__tiy" onClick={onQuizClick} style={{ color: "white", marginLeft: "0px", paddingLeft: "0px" }}>
          {/* dynamic content load not working quiz always with dnd <Quiz quiz={content} onComplete={onCompleteAction} continueTillCorrect={true} showInstantFeedback={true} showDefaultResult={false} customResultPage={renderCustomResultPage} style={{ color: "white" }}/> */}
          <Quiz quiz={content} continueTillCorrect={true} showInstantFeedback={true} showDefaultResult={false} customResultPage={renderCustomResultPage} style={{ color: "white" }}/>
      </div>

    </>
  );
}

export default QuizCustom;
