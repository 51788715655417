import React from "react";

export const quizContent =
    [

        //QUIZ 0: Learning by Playing
        {
            "id": "0",
            "quizTitle": "Quiz: Learning by Playing",
            "quizSynopsis": "This quiz prepares you for the different types of questions you could encounter in Muco. Nothing difficult: you'll need just a little bit of attention and accuracy ;) Good luck! ",
            "questions": [
                {
                    "question": "Muco is designed for:",
                    "questionType": "text",
                    "questionPic": "https://www.insidehighered.com/sites/default/server_files/media/barber%20handshake.jpg", // if you need to display Picture in Question
                    "answerSelectionType": "single",
                    "answers": [
                        "learning cooking and singing",
                        "learning music and coding",
                        "mining and earning",
                        "all of above"
                    ],
                    "correctAnswer": "2",
                    "messageForCorrectAnswer": "Correct answer. Good job!",
                    "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
                    "explanation": "Muco stands for MUsic and COding. We want to show how much music and coding have in common, so why not learn them together?",
                    "point": "20"
                },
                {
                    "question": "To start learning with Muco you have to know",
                    "questionType": "text",
                    "answerSelectionType": "single",
                    "answers": [
                        "C#",
                        "how many sharps are in C major",
                        "nothing at all"
                    ],
                    "correctAnswer": "3",
                    "messageForCorrectAnswer": "You're right!",
                    "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
                    "explanation": "Muco has no prerequisites at all, we gonna teach you everything from scratch!",
                    "point": "20"
                },
                {
                    "question": "Choose a synthesizer",
                    "questionType": "photo",
                    "answerSelectionType": "single",
                    "answers": [
                        "https://upload.wikimedia.org/wikipedia/commons/e/e4/Micromoog.jpg",
                        "https://upload.wikimedia.org/wikipedia/commons/3/3d/Two_pianos_-_grand_piano_and_upright_piano.jpg",
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Pipe.organ.console.arp.jpg/1200px-Pipe.organ.console.arp.jpg"
                    ],
                    "correctAnswer": "1",
                    "messageForCorrectAnswer": "Sure.",
                    "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
                    "explanation": "In Muco we will focus on exploring and creating electronic music using computer-based synthesizers. ",
                    "point": "20"
                },
                {
                    "question": "We will learn how to use:",
                    "questionType": "text",
                    "answerSelectionType": "multiple",
                    "answers": [
                        "modulation",
                        "waveshaping",
                        "delays"
                    ],
                    "correctAnswer": [1, 2, 3],
                    "messageForCorrectAnswer": "YES!",
                    "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
                    "explanation": "Everything. And much more…",
                    "point": "20"
                },
            ]
        },

        //QUIZ 1: Sound and Waves
        {

        },

        //QUIZ 2: Frequency and Pitch
        {

        },

        //QUIZ 3: Different Waveforms
        {
            "id": "3",
            "quizTitle": "Quiz: Different Waveforms",
            "quizSynopsis": "\"The wise man doesn't give the right answers, he poses the right questions\" Claude Levi-Strauss",
            "questions": [
                {
                    "question": "What could've produced such sound waves",
                    "questionType": "text",
                    "questionPic": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Sine_waves_different_frequencies.svg/1280px-Sine_waves_different_frequencies.svg.png", // if you need to display Picture in Question
                    "answerSelectionType": "single",
                    "answers": [
                        "Grand piano",
                        "Violin",
                        "Thunder",
                        "Digital synthesizer"
                    ],
                    "correctAnswer": "4",
                    "messageForCorrectAnswer": "That wasn't' an easy one, well done!",
                    "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
                    "explanation": "The sine waves on the picture are perfect: they do not dissipate energy over time and have exact frequency and amplitude. Only electric synthesizers are capable of producing such waves.",
                    "point": "20"
                },
                {
                    "question": "Look at the sound waves in the pictures. What do they have in common",
                    "questionType": "text",
                    "questionPic": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Waveforms.svg/800px-Waveforms.svg.png", // if you need to display Picture in Question
                    "answerSelectionType": "multiple",
                    "answers": [
                        "they sound the same",
                        "same amplitude",
                        "same fundamental frequency"
                    ],
                    "correctAnswer": [2, 3],
                    "messageForCorrectAnswer": "Exactly!",
                    "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
                    "explanation": "Amplitude (vertical difference between peaks) and fundamental frequency (number of oscillations per time) are the same. However because of the different wave form, these sounds have different Timbre and we hear them differently",
                    "point": "20"
                }
            ]
        },

        //QUIZ TEMPLATE REACT
        {

        }
    ]
