import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from 'react-ga';

const baseUrl = 'https://muco.fh-campuswien.ac.at/api/public';

async function register(credentials) {
    return fetch(baseUrl + '/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default function SignUp() {

    const passwordField1 = React.useRef(null);
    const passwordField2 = React.useRef(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const [password2, setPassword2] = useState();

    function infoToGa(){
        ReactGA.event({
            category: 'Login',
            action: 'Sign Up',
            label: 'click'
        });
    }

    const signUp = async e => {
        e.preventDefault();

        if (password !== password2) {
            toast.error('Passwords do not match!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } else {
            const response = await register({
                username,
                email,
                password,
                password2
            });
            if (response) {
                toast.success('Sign-up successfull!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleClose();
                ReactGA.event({
                    category: 'Login',
                    action: 'Sign Up',
                    label: 'success'
                });
    
            } else {
                ReactGA.event({
                    category: 'Login',
                    action: 'Sign Up',
                    label: 'fail'
                });
    
            }
        }
    }

    return (
        <div>
            <p className="w3-right-align w3-text-blue" onClick={handleShow} >
                Don't have an account yet? Sign up!
            </p>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign up!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={signUp}>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="username" onChange={e => setUserName(e.target.value)} placeholder="Username" required />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" onChange={e => setEmail(e.target.value)} placeholder="Enter email" required />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group ref={passwordField1} as={Col} controlId="formGridPassword1">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" onChange={e => setPassword(e.target.value)} placeholder="Password" required />
                            </Form.Group>
                            <Form.Group ref={passwordField2} as={Col} controlId="formGridPassword2">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" onChange={e => { setPassword2(e.target.value) }} placeholder="Password" required />
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-3" id="formGridCheckbox">
                            <Form.Check type="checkbox" label="By checking this box you agree to the terms and conditions. " required />
                        </Form.Group>

                        <Button className="w3-right" onClick={infoToGa} variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div>
    );
}