//https://www.npmjs.com/package/react-ga
//https://damianztone.medium.com/google-analytics-and-react-js-2021-cce4ce0ac0b5
import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-210099792-1';

export const initGA = () => {
    ReactGA.initialize(TRACKING_ID);
};

export const trackingPageGA = (page) => {
    ReactGA.pageview(page);
};

export const eventGA = (categoryName, eventName) => {
    ReactGA.event({
        category: categoryName, // Required
        action: eventName, // Required
        label: 'labelName',
        value: 10,
        nonInteraction: false,
    });
};