import React from "react";
import { CircularProgressBar } from "@tomik23/react-circular-progress-bar";
import Card from 'react-bootstrap/Card'

//https://www.npmjs.com/package/@tomik23/react-circular-progress-bar

const BadgeCard = (props) => {

    const { awardName, percentage, type } =
        props;

    // available control variables - more see ling above
    const config = {
        percent: percentage,
        colorSlice: "#CDDC39",
        colorCircle: "#f1f1f1",
        fontWeight: 100,
        fontSize: "1rem",

    };

    return (
        <>
            <Card className="w3-round-large" style={{ width: '17%', height: '17%' }}>
                <Card.Body className="w3-center">
                    <h3>{type}: </h3> <h4>{awardName}</h4>
                </Card.Body>
                <CircularProgressBar className="w3-center" {...config}>
                </CircularProgressBar>
            </Card>
        </>
    );
};

export default BadgeCard;
