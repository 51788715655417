import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import './App.css';
import Login from './login/Login';
import Sidebar from "./Sidebar";
import LearningOverview from "./learning/LearningOverview";
import Rewards from "./rewards/Rewards";
import Error from "./Error";
import useToken from './login/useToken';
import Dashboard from './dashboard/Dashboard.js';
import {initGA, trackingPageGA} from "./Analytics/reactGA";

function App() {

    initGA();
    trackingPageGA(window.location.pathname+window.location.search);

    const { token, setToken } = useToken();

    if (!token) {
        return <Login setToken={setToken} />
    }

    return (
        <div className="wrapper">
            <Router>
                <Sidebar />

                <Switch>
                    <Route exact path="/">
                        <Dashboard />
                    </Route>

                    <Route path="/learning">
                        <LearningOverview />
                    </Route>

                    <Route path="/rewards">
                        <Rewards />
                    </Route>

                    <Route path="*">
                        <Error />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;