export const codeTiyContent = 
[
    {
      id: 0,
      title: "Learning By Playing",
      code: "learning-by-playing",
    },
    {
        id: 1,
        title: "Learning By Playing",
        code: "learning-by-playing",
      },
]