import React from "react";
import QuizCustom from "../gamification/quizCustom";
import { quizContent } from "../data/content/quiz-content";
import { dndSortingContent } from "../data/content/dnd-sorting-content";
import { codeTiyContent } from "../data/content/code-tiy-content";
import CodeEditorCustom from "../gamification/code-editor/CodeEditorCustom";
import DndSorting from "../gamification/dnd-sorting/DndSorting";
import ReactGA from "react-ga";

function TryItYourself(props) {
  const { id, title, instructions, exampleCode } = props;

  const activeSorting = dndSortingContent.find(
    (item) => item.id === id
  );

  return (
    <>
      <div className="learning__tiy">
        <h2 className="learning__tiy__text">{title} </h2>
        <h5 className="learning__tiy__text">{instructions}</h5>

        {(codeTiyContent[id]) &&
          <CodeEditorCustom id={id} />}

        {(quizContent[id].id) &&
          <QuizCustom id={id} quizContent={quizContent[id]} />
        }

        {dndSortingContent[id] &&
          <DndSorting id={id} dndContent={dndSortingContent[id]} />
        }
      </div>
    </>
  );
}

export default TryItYourself;
