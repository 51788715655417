import * as React from 'react';
import { List, arrayMove } from 'react-movable';
import { rewardContent } from "./../../data/content/reward-content";
import { ToastContainer, toast } from 'react-toastify';
import { useState } from "react";
import ReactGA from "react-ga";
import { Image } from "react-bootstrap";

function DndSorting(props) {
    // dndContent is already the correct Object/array for this learning module
    const { dndContent, id } = props;
    const [listToBeSorted, setListToBeSorted] = React.useState([]);
    const desiredSorting = [...dndContent.listSorted];
    const token = (JSON.parse(window.sessionStorage.getItem('token')));
    var stringTokenToken = (JSON.stringify(token.token));

    var url = `https://muco.fh-campuswien.ac.at/api/private/students/` + token.student.studentId + `/activeLevel/`;

    // This part is for the next Button to be actice/inactive
    const student = (JSON.parse(window.sessionStorage.getItem('token')).student);
    var [activeLevel, setActiveLevel] = React.useState(0);
    var [nextLevel, setNextLevel] = React.useState(0);

    React.useEffect(() => {
        setNextLevel(id + 1);
        setActiveLevel(student.activeLevelId - 1);
        if (nextLevel <= activeLevel) {
            //document.querySelector('#linkToNextModule').style.pointerEvents = true;
            //document.querySelector('#linkToNextModule').className = "col";
            // == is correct
        } else {
            document.querySelector('#linkToNextModule').style.pointerEvents = "none";
            document.querySelector('#linkToNextModule').className = "col w3-opacity";
        }
    }, [activeLevel, id, nextLevel, student.activeLevelId])

    //This is for the Elements to be refreshed when someone moves to a new level
    React.useEffect(() => {
        setListToBeSorted([...dndContent.listToBeSorted]);
        // Elements are used in dnd sorting component
        document.getElementById("whenFalse").hidden = true;
        document.getElementById("whenCorrect").hidden = true;
    }, [dndContent.listToBeSorted])

    // calls the BE to update the Level of the Logged in User
    async function updateLevel() {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: `'${stringTokenToken}'` })
        };
        var nextLevel = token.student.activeLevelId + 1;
        const response = await fetch(url + nextLevel, requestOptions);
        const data = await response.json();
        token.student = data.student;
        var stringToken = (JSON.stringify(token));
        window.sessionStorage.setItem('token', stringToken);
        return true;
    }

    const checkSorting = () => {
        console.log(JSON.stringify(listToBeSorted) === JSON.stringify(desiredSorting));

        // comparing both arrays using stringify
        if (JSON.stringify(listToBeSorted) === JSON.stringify(desiredSorting)) {

            // from Stepan GoogleAnalytics
            ReactGA.event({
                category: 'DND',
                action: 'Check',
                label: 'right'
            });
            if (typeof dndContent.play === 'function') {
                dndContent.play();
            }

            document.getElementById("whenCorrect").hidden = false;
            document.getElementById("whenFalse").hidden = true;
            if (id == activeLevel) {
                const finished = updateLevel();
                if (finished) {
                    document.querySelector('#linkToNextModule').style.pointerEvents = "";
                    document.querySelector('#linkToNextModule').className = "col";
                }
                const message = "Woohoo congratulations, you have unclocked the next level and earned the " + rewardContent[id].title + " Reward!";
                toast.success(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } 
        } else {

            // from Stepan GoogleAnalytics
            ReactGA.event({
                category: 'DND',
                action: 'Check',
                label: 'wrong'
            });

            document.getElementById("whenCorrect").hidden = true;
            document.getElementById("whenFalse").hidden = false;
        }
    }


    return (

        <div id="dnd" className='drag_food_to_animals col-md-6'>
            {/*custom title for the DND if set*/}
            <h3>{dndContent.title ? dndContent.title : "Demo: Please sort the below elements with Drag and Drop. Please Click the button below to see whether you are right."}</h3>
            <p></p>
            <div></div>
            {(dndContent.imgURL !== undefined) &&
                <div >
                    <Image className="wide-image"
                        src={dndContent.imgURL}
                    />
                    <br></br>
                    <br></br>
                </div>
            }
            <List id="dndList"
                values={listToBeSorted}
                onChange={({ oldIndex, newIndex }) => {
                    setListToBeSorted(arrayMove(listToBeSorted, oldIndex, newIndex));
                    ReactGA.event({
                        category: 'DND',
                        action: 'Interaction'
                    });
                }
                }

                renderList={({ children, props }) => <ul className="list-group" {...props}>{children}</ul>}
                renderItem={({ value, props }) => <li className="list-group-item" {...props}>{value}</li>}
            />
            <br></br>
            <div id="whenCorrect" className="alert alert-success" role="alert" hidden>{dndContent.successMessage ? dndContent.successMessage : "Correct! Well done! :)"}</div>
            <div id="whenFalse" className="alert alert-danger" role="alert" hidden>Oh no! Try again! :)</div>
            <button id="checkButton" onClick={checkSorting} >Check sorting</button>
            <ToastContainer />
        </div>
    );

} export default DndSorting;