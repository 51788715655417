import React from "react";
import { Card } from "react-bootstrap";
import { rewardContent } from "./../data/content/reward-content";

function Rewards() {
  const student = JSON.parse(window.sessionStorage.getItem("token")).student;
  var disable;

  return (
    <>
      <div className="page-container">
        <main className="main">
          <h1>Rewards</h1>
          <div className="cards-container">
            {rewardContent.map((rewardItem) => {
              const { id, title, pathToImage } = rewardItem;

              if (id < student.activeLevelId) {
                disable = " ";
                // == is correct
              } else if (id == student.activeLevelId) {
                disable = "w3-sepia-max";
              } else {
                disable = "w3-grayscale-max w3-opacity-max";
              }

              return (
                <Card
                  className="card-container"
                  style={{
                    position: "center",
                    height: "18rem",
                    width: "18rem",
                  }}
                >
                  <h4
                    className="card-container__title_rewards"
                    style={{ color: "green", textAlign: "center" }}
                  >
                    {title}
                  </h4>
                  <div className="w3-center">
                    <img
                      src={pathToImage}
                      className={`card-container__icon ${disable}`}
                      style={{ position: "center", paddingLeft: "1.5rem" }}
                      alt="Test test"
                    ></img>
                  </div>
                </Card>
              );
            })}
          </div>
        </main>
      </div>
    </>
  );
}

export default Rewards;
