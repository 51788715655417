import React from "react";

function CodeEditorCustom(props) {
  return (
    <iframe
      height="300"
      style={{ width: 100 + "%" }}
      scrolling="no"
      title="Tone.js Test"
      src="https://codepen.io/iriseib/embed/preview/dyWrEoL?default-tab=js%2Cresult&editable=true"
      frameBorder="no"
      loading="lazy"
      allowtransparency="true"
      allowFullScreen={true}
    >
      See the Pen{" "}
      <a href="https://codepen.io/iriseib/pen/dyWrEoL">Tone.js Test</a> by
      IrisEib (<a href="https://codepen.io/iriseib">@iriseib</a>) on{" "}
      <a href="https://codepen.io">CodePen</a>.
    </iframe>
  );
}
export default CodeEditorCustom;
