import React from "react";
import Highlighter from "react-highlight-words";

function Theory(props) {
  const { title, description, keywords } = props;

  const descriptionParagraphs = description.split("\n").map((str, i) => (
    <h5 key={i} className="learning__theory__text">
      <Highlighter
        highlightClassName="learning__theory__text--highlighted"
        searchWords={keywords}
        autoEscape={true}
        textToHighlight={str}
      />
    </h5>
  ));

  return (
    <>
      <div className="learning__theory">
        <h2>{title}</h2>
        {descriptionParagraphs}
      </div>
    </>
  );
}

export default Theory;
