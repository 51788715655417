import * as Tone from "tone";
import fifth from "../../data/images/fifth.png";

export const dndSortingContent =
[
    //TODO: DO NOT CHANGE THE ORDER! The content is chosen by array order, not by id
    {
        "id": "0",
        "title": "Please arrange the Muco learning modules' sections in the right order via Drag and Drop",
        //TODO: One sorted list is enough. The list to be sorted can be rendered randomly
        "listToBeSorted": ['Blue: Try it out yourself', 'Yellow: Theoretical musical background', 'Orange: Coded audio example'],
        "listSorted": ['Yellow: Theoretical musical background', 'Orange: Coded audio example', 'Blue: Try it out yourself'],
        
    },
    {
        "id": "1",
        "title": "Arrange the lines of code in the right order",
        "listToBeSorted": ['perfectCPitch.toDestination().start();', 'perfectCPitch = new Tone.Oscillator(\'523\',\'square\');', 'let perfectCPitch;'],
        "listSorted": ['let perfectCPitch;', 'perfectCPitch = new Tone.Oscillator(\'523\',\'square\');', 'perfectCPitch.toDestination().start();'],
        
    },
    {
        "id": "2",
        "title": "A single line of the code can produce a sound. Arrange the chunks in the right order!",
        "listToBeSorted": ['new Tone', 'const tone = ', ';','.Oscillator(\'C#3\', \'square\')','.start()','.toDestination()'],
        "listSorted": ['const tone = ', 'new Tone', '.Oscillator(\'C#3\', \'square\')','.toDestination()','.start()',';'],
        "successMessage": "Well done! 'const tone = new Tone.Oscillator('C#3', 'square').toDestination().start();' will declare tone and initialize a new Tone.Oscillator object with arguments of pitch C#-3 and square wave form. Then we send it to the speakers and start the sound! :)"
    },
    {
        "id": "3",
        "imgURL": fifth,
        "title": "Take a look at the picture! Can you arrange the code, so that synthesizer produces similar sound?",
        "listToBeSorted": ['let osc = new Tone.Oscillator(392, "sine").toDestination().start(now).stop(now + 0.3);', 'const now = Tone.now();', 'osc =  new Tone.Oscillator(311.13, "sawtooth").toDestination().start(now + 1.05).stop(now + 1.65);','osc = new Tone.Oscillator(392, "square").toDestination().start(now + 0.7).stop(now + 1);','osc = new Tone.Oscillator(392, "triangle").toDestination().start(now + 0.35).stop(now + 0.65);'],
        "listSorted": ['const now = Tone.now();', 'let osc = new Tone.Oscillator(392, "sine").toDestination().start(now).stop(now + 0.3);', 'osc = new Tone.Oscillator(392, "triangle").toDestination().start(now + 0.35).stop(now + 0.65);','osc = new Tone.Oscillator(392, "square").toDestination().start(now + 0.7).stop(now + 1);','osc =  new Tone.Oscillator(311.13, "sawtooth").toDestination().start(now + 1.05).stop(now + 1.65);'],
        "successMessage": "TA DA DA DA!!! Exactly! Not only the wave forms are different, but you could also look at the times and don't forget the lower (=less oscillations per time) last pitch",
        play: function () {
            const now = Tone.now();

            let osc = new Tone.Oscillator(392, "sine").toDestination().start(now).stop(now + 0.3);
            osc = new Tone.Oscillator(392, "triangle").toDestination().start(now + 0.35).stop(now + 0.65);
            osc = new Tone.Oscillator(392, "square").toDestination().start(now + 0.7).stop(now + 1);
            osc =  new Tone.Oscillator(311.13, "sawtooth").toDestination().start(now + 1.05).stop(now + 1.65);

            osc =  new Tone.Oscillator(349.23, "sine").toDestination().start(now + 2.3).stop(now + 2.6);
            osc = new Tone.Oscillator(349.23, "triangle").toDestination().start(now + 2.65).stop(now + 2.95);
            osc = new Tone.Oscillator(349.23, "square").toDestination().start(now + 3).stop(now + 3.3);
            osc =  new Tone.Oscillator(293.66, "sawtooth").toDestination().start(now + 3.35).stop(now + 4.55);
        },
    },
    {
        "id": "4",
        "title": "Let's recapitulate some programming theory from the last module: how do you structure the statements defining a for loop?",
        "listToBeSorted": ['i+=1', 'let i = 0', 'i < 4'],
        "listSorted": ['let i = 0', 'i < 4', 'i+=1'],
        "successMessage": "Well done! :)"
    }
]

