export const rewardContent = 
[
    {
        "id": "1",
        "title": "Newbie",
        "pathToImage": "/images/rewards/Diploma.png",
        
    },
    {
        "id": "2",
        "title": "Rookie",
        "pathToImage": "/images/rewards/Evaluation.png",
        
    },
    {
        "id": "3",
        "title": "Explorer",
        "pathToImage": "/images/rewards/Planet.png",
        
    },
    {
        "id": "4",
        "title": "Apprentice",
        "pathToImage": "/images/rewards/Square academic cap.png",
        
    },
    {
        "id": "5",
        "title": "Adventurer",
        "pathToImage": "/images/rewards/Globus.png",
        
    },
    {
        "id": "6",
        "title": "Smart Cookie",
        "pathToImage": "/images/rewards/Presentation.png",
        
    },
    {
        "id": "7",
        "title": "Master",
        "pathToImage": "/images/rewards/Scroll.png",
        
    },
    {
        "id": "8",
        "title": "Hero",
        "pathToImage": "/images/rewards/Medal.png",
        
    },
    {
        "id": "9",
        "title": "Wizard",
        "pathToImage": "/images/rewards/Physics.png",
        
    },
    {
        "id": "10",
        "title": "Superstar",
        "pathToImage": "/images/rewards/Patent.png",
        
    }
]

