import React, { Component } from 'react'
import { BiLogOut } from "react-icons/bi"

export default class Logout extends Component {

    // https://www.tutorialswebsite.com/logout-functionality-in-react/
    logout = () => {
        sessionStorage.clear();
        window.location.href = "/";
    }

    render() {
        return (
            <div className="logout" onClick={this.logout}>
                <BiLogOut/>
                <span className="link-text">Logout</span>
            </div>
        )
    }
}