import React from "react";

import * as Tone from "tone";

// TODO: import all necessary react icons manually // is there a better way to do that?
import { FaCodeBranch, FaWaveSquare, FaRegKeyboard } from "react-icons/fa";
import { GiSoundWaves } from "react-icons/gi";
import { FaLock } from "react-icons/fa";
import { CgData } from "react-icons/cg";
import { RiSoundModuleLine } from "react-icons/ri";

// TODO: a list of TODOs to go through before first testing phase
// 1. Check if all properties are consistent for all objects
// 2. Check if all necessary properties are destructured in Learning.js and LearningOverview.js (+ also check for redundant props!)
//

// Declare global vars
let loop;
let bassSynth, cymbalSynth;
let synth;
let osc;
let counter;
let osc1, osc2, osc3, osc4;

export const learningContent = [
  // 0 - Learning By Playing (FIN)
  {
    id: 0,
    topicId: "learning-by-playing",
    title: "Learning By Playing",
    icon: <FaCodeBranch />,
    moduleColour: "orange",
    shortDescription:
      "Welcome to muco! In this module, we will give you a little overview into what's to come and what you are about to learn.",
    active: true,
    theoryTitle: "Write music in code",
    theoryDescription:
      "Muco is an interactive learning application designed for creating music with code. Each learning module is structured in three parts: in the first part (this yellow section!), we will provide you with some theoretical background on a certain musical background. In the second part (the orange section below), we will show you a coded audio example illustrating that concept. Finally, in the last part (the blue section on the bottom!) you will be encouraged to try it out yourself. By solving quizzes and playing mini-games, you can test your knowledge and challenge yourself. For some learning modules you can also write your own code in order to produce your own sounds and explore new musical timbres. \nIn the theory parts of the upcoming learning modules, you will learn how to make electronic music using computer-based synthesizers. We will show you the basic building blocks of synthesizers, including their 'sound generation unit', which can be either based on oscillators or on samples. We will show you how to add effects such as modulation, waveshaping and delays (and explain those!), and in the end show you how to combine those to make your own music. All important terms and definitions will be highlighted like this. \n Last but not least, a few words on any prerequisites: No coding or musical background is required! Muco is designed as a beginner-friendly learning plattform. You will learn about both coding and music through theoretical discussions and experimental examples.",
    theoryKeywords: [
      "synthesizers",
      "timbres",
      "oscillators",
      "samples",
      "modulation",
      "waveshaping",
      "delays",
      "highlighted like this",
    ],
    exampleTitle: "Let's create some drums!",
    exampleCode:
      'let bassSynth;\nlet loop;\n\nbassSynth = new Tone.MembraneSynth().toDestination();\n\nfunction drum(time) {\n\tbassSynth.triggerAttackRelease("c1", "8n", time);\n}\nloop = new Tone.Loop(drum, "4n");\n\nloop.start(0);\n\n',
    exampleDescription: [
      "Let's look at our first code example! In the example below, we have two variables called bassSynth and loop. We initialize the bassSynth variable as a new Tone.MembraneSynth() object, and connect it to the master output (the speakers of your device) using .toDestination().\nNext, we define a function called drum, that takes in one argument called time. In the function body, we play our bass synth on the middle 'C' for the duration of an 8th note, and pass in the time argument as the last parameter. Then we initialize our loop variable as a new Tone.Loop() object, and hand in our drum function as a callback, and a 4th note as our time variable. Finally, we get our loop started.\nYou can listen to each example by clicking on the 'run' button below. To stop the audio, simply click 'stop'.",
      "At this point you have probably not understood the code above completely. Don't worry though - we will work our way through these concepts step by step in the upcoming modules. Just stay curious!",
    ],
    exampleCodeVars: [
      "bassSynth",
      "loop",
      "drum",
      "time",
      "Tone.MembraneSynth()",
      "Tone.Loop()",
    ],
    exampleCodeConcepts: [
      "variables",
      "loop",
      "callback",
      "function",
      "function body",
      "argument",
      "parameter",
    ],
    play: function () {
      bassSynth = new Tone.MembraneSynth().toDestination();

      function drum(time) {
        bassSynth.triggerAttackRelease("c1", "8n", time);
      }
      loop = new Tone.Loop(drum, "4n");

      loop.start(0);
      Tone.Transport.start();
    },
    stop: function () {
      Tone.Transport.stop();
    },
    tiyTitle: "Try it out yourself",
    tiyInstructions:
      "As mentioned earlier, in the blue section you will get the change to get your hands dirty. In this first introductory module, we will give you some instructions on how to tackle which kind of problem.",
    tiyKeywords: [], // TODO: include instructions!
  },

  // 1 - SOUND AND WAVES (FIN)
  {
    id: 1,
    topicId: "sound-and-waves",
    title: "Sound and Waves",
    icon: <FaRegKeyboard />,
    moduleColour: "red",
    shortDescription:
      "In this first module, we will explore the phenomenon of sound from a physical point of view.",
    active: false,
    generalDescription: "",
    theoryTitle: "On the nature of sound",
    theoryDescription:
      "Before we look into synthesizers, let's first talk about the nature of sound. From a physical point of view, performing music results in sounds or acoustic waves. Any natural sound is generated by a vibrating object and can be thought of as energy in motion, that is, an oscillation traveling from source to destination through some medium.\nWhile naturally generated sound waves dissipate energy over time (causing the sound to disappear), mechanically and electronically produced soundwaves continuously and periodically produce repeating waves without losing energy. The simplest form of such a periodic waveform is the sinusoid, or sine wave.\nSimilar as in natural sound sources, any synthesizer also includes a sound-generating unit. In its simplest form, this would be an oscillator. Before we move on to other aspects which define a sound, let's explore oscillators in a code example!",
    theoryKeywords: [
      "nature of sound",
      "acoustic waves",
      "sound",
      "energy in motion",
      "oscillation",
      "periodically produce repeating waves",
      "periodic waveform",
      "sinusoid",
      "sine wave",
      "sound-generating unit",
      "oscillator",
    ],
    exampleTitle: "Exploring oscillators",
    exampleCode:
      "let osc;\nosc = new Tone.Oscillator(440, 'sine');\nosc.toDestination();\nosc.start();",
    exampleDescription: [
      "In the code below, we first declare a variable called osc using the let keyword. Variables can be thought of as named containers which hold a specific value. In the next line, we assign a value to the osc variable by initializing it as a new Tone.Oscillator() object (more on objects in a bit!).\nNote the difference between variable declaration and variable initialization: while the former simply creates a variable without assigning any value, the latter actually stores a value. Before you assign a value to a variable (that is, before the initialization), any variable declared will have the value undefined.\nThe Tone.Oscillator() object takes in two arguments: the first indicates the frequency, and the second defines the waveform (more on that in the next module!). In our example, we want the oscillator to create a sine tone with a frequency of 440 Hz (we will cover the concept of frequency in the next module!).\nNow, moving on to the next two lines: In the third line, we connect our created oscillator object to your device speakers by calling the method .toDestination(). Finally, in the last line we start making sound 🥳",
      " ",
    ],
    exampleCodeVars: [
      "osc",
      "const",
      "Tone.Oscillator()",
      "sine",
      "440",
      ".toDestination()",
    ],
    exampleCodeConcepts: [
      "variable",
      "object",
      "objects",
      "variable declaration",
      "variable initialization",
      "undefined",
      "arguments",
    ],
    play: function () {
      osc = new Tone.Oscillator(440, "sine");
      osc.toDestination().start();
    },
    stop: function () {
      osc.stop();
    },
    tiyTitle: "Test yourself!",
    tiyInstructions:
      "As explained above, will result in different pitches. In the code editor below, test out different frequency values to see how they affect the sound created!",
    tiyKeywords: ["different frequency values", "different pitches"],
  },

  // 2 - Frequency and Pitch (FIN)
  {
    id: 2,
    topicId: "frequency-pitch",
    title: "Frequency and Pitch",
    icon: <GiSoundWaves />,
    moduleColour: "green",
    shortDescription:
      "In this module we will cover the aspect of frequency, or pitch, of a sound. You will get to know different data types and learn how to write code more concisely.",
    active: false,
    theoryTitle: "More sound aspects",
    theoryDescription:
      "In the coding part of the last module, we have created an oscillator which produced a sinusoidal wave with a frequency of 440 Hz. Generally speaking, frequency is defined as the number of occurrences of a repeating event per time unit. In the context of sound, frequency, measured in Hertz (Hz), is the number of sound waves per second.\nThe higher the frequency of a sinusoidal wave, the higher it will sound. The human ear can only perceive a limited range of frequencies from about 20 Hz to 20,000 Hz (20 kHz). This spectrum is also known as the human audible frequency range, and can change over time (with the upper bound usually decreasing with age).\nThe notion of frequency is closely related to what we call the pitch of a sound.  For example, a sinusoid with a frequency of 440 Hz corresponds to the pitch A4. This particular pitch is usually used as a reference and commonly known as the concert pitch.\nBefore we dive deeper into the concept of frequency and pitch, let's explore different frequency values in the code below.",
    theoryKeywords: [
      "oscillator",
      "frequency",
      "Hertz (Hz)",
      "number of sound waves per second",
      "human audible frequency range",
      "pitch",
      "440 Hz",
      "A4",
      "concert pitch",
    ],
    exampleTitle: "Spot the difference!",
    exampleCode:
      "let osc;\nosc = new Tone.Oscillator('A4', 'sine');\nosc.toDestination().start();",
    exampleDescription: [
      "The code example below looks almost identical to the one from the last module - can you spot the difference? (Hint: There are two 🤓)",
      "The first difference is the argument value for the frequency of the oscillator object. In the last lecture, we defined the frequency in Hertz, and therefore handed in '440' as the argument value. In this example, we have defined the frequency in terms of its pitch, 'A4'. Both will yield the same result, they are simply two different data types: 440 is a number type and 'A4' is a string type.\nThe second difference covers the important concept of method chaining. In the last lecture, we first connected our osc variable to the output, and only started playing in the next line. You can write more concise code by chaining methods: this way, each function passes its return value to the next method in the chain. When doing so, however, make sure you are chaining the methods in the right order!",
    ],
    exampleCodeVars: [
      "osc",
      "const",
      "Tone.Oscillator()",
      "sine",
      "440",
      ".toDestination()",
    ],
    exampleCodeConcepts: [
      "argument value",
      "data types",
      "number",
      "string",
      "method chaining",
    ],
    play: function () {
      osc = new Tone.Oscillator("A4", "sine");
      // // osc = new Tone.Oscillator(69, "sine");
      osc.toDestination().start();
    },
    stop: function () {
      osc.stop();
    },
    tiyTitle: "Try out method chaining for yourself!",
    tiyInstructions: "",
    tiyKeywords: [],
  },

  // 3 - Different Waveforms ()
  {
    id: 3,
    topicId: "waveforms",
    title: "Different Waveforms",
    icon: <FaWaveSquare />,
    moduleColour: "blue",
    shortDescription:
      "In this module, we will show you another important feature of a sound wave: its form!",
    active: false,
    theoryTitle: "Fundamental frequency and partials",
    theoryDescription:
      "In the last module we learned about frequency, or pitch, as an important aspect of a sound, and you probably tried out different frequency values using a sine wave in the code editor at the end of the module. In fact, musical sounds are made up of a superposition of sinusoids, each with its own frequency and amplitude (more on the amplitude aspect of sound in the next module!). In this regard, each of the sine waves making up the sound is also called a partial.\nThe frequency of the lowest partial is called the fundamental frequency, which determines the overall pitch of the sound. A harmonic is defined as a partial that is an integer multiple of the fundamental frequency. By contrast, an inharmonic partial is any partial that does not match an ideal harmonic. In music theory, all partials except for the first one (the fundamental frequency) are also called overtones.\nWe will talk about harmonicity in more depth in future modules. For the next concept, it's enough for you to understand the concepts of fundamental frequency and overtones.\nEarlier we have created an oscillator which produced a sinusoid. The sine wave is the simplest of all waveforms, containing only a single fundamental frequency and no overtones. Next to the sine wave, there are three other types of primary waveforms: the square, the triangle, and the sawtooth. Each of them has their own unique spectrum of overtones. Let's listen to their different sounds below.",
    theoryKeywords: [
      "frequency",
      "pitch",
      "superposition of sinusoids",
      "amplitude",
      "partial",
      "fundamental frequency",
      "partials",
      "harmonic",
      "inharmonic",
      "overtones",
      "oscillator",
      "sine",
      "primary waveforms",
      "square",
      "triangle",
      "sawtooth",
      "sounds",
    ],
    exampleTitle: "Looping through different waveforms",
    exampleCode:
      '// Creating the waves array\nconst waves = ["sine", "triangle", "square", "sawtooth"];\nconst now = Tone.now();\n\n// Creating the for loop\nfor (let i = 0; i < 4; i+=1) {\n\t// Declaring the code we want to execute i < 4 times \n\tlet osc = new Tone.Oscillator("A4", waves[i]);\n\tosc.toDestination().start(now + i);\n\tosc.stop(now + i + 0.5);\n}\n',
    exampleDescription: [
      'In the code below, we define an array called waves. You can think of arrays as variables in which you can store multiple values, which you can then loop through. An array is created by declaring a variable via the const keyword (as we did until now), and initialized by storing multiple values within [square brackets]. Note that in the example below, as we are storing string values, we have to store each one in separate "quotation marks". Each of the individual values in the array is separated by a comma.\nNow, we just mentioned you can loop through these values - what does this actually mean? Loops in a programming language allow you to run the same block of code multiple times. There are different kinds of loops, and in this lesson we will cover the for loop. \nThe for loop uses the keyword for and takes in three statements which are separated by semicolons and all wrapped in (brackets): \n\t1. The first statement is used to define the initial variable used in the loop (here: let i=0). This statement is executed once before the execution of the code block inside the loop \n\t2. The second statement defines the condition which is used to evaluate how many times the code block inside will be executed (here, as long as i < 4 the loop is going to continue) \n\t3. The third statement is used to define how the initial variable is handled in each loop iteration.  In our example, we are positively incrementing the loop variable in each iteration.',
      "Finally, let’s look at the code block inside the for loop, which you will (for the most parts) recognize from the previous modules. Let’s go over it line by line. In the first line, we initialize an osc variable as a Tone.Oscillator object again, and pass in ‘A4’ (pitch) as an argument and one item of the waves array. We can access a specific value of the array through square brackets ([]) again.\nThis module is already getting a bit long, so we will continue our discussion on loops and how they work in the next module. For now,  let’s have a look at some errors commonly made when using a for loop.",
    ],
    exampleCodeVars: [],
    exampleCodeConcepts: [
      "array",
      "const",
      "[square brackets]",
      "string",
      '"quotation marks"',
      "for loop",
      "three statements",
      "(brackets)",
      "first statement",
      "initial variable",
      "second statement",
      "condition",
      "third statement",
      "how the initial variable is handled in each loop iteration",
      "positively incrementing",
      "code block",
    ],
    play: function () {
      const waves = ["sine", "triangle", "square", "sawtooth"];
      const now = Tone.now();
      for (let i = 0; i < 4; i += 1) {
        let osc = new Tone.Oscillator("A4", waves[i]);
        osc.toDestination().start(now + i);
        osc.stop(now + i + 0.5);
      }
    },
    stop: function () {
      // osc.stop();
    },
    tiyTitle: "",
    tiyInstructions: "",
    tiyKeywords: [],
  },

  // 4 Dynamics and Loudness
  {
    id: 4,
    topicId: "dynamics",
    title: "Dynamics and Loudness",
    icon: <CgData />,
    moduleColour: "orange",
    shortDescription:
      "In this module we will learn how to change the dynamics, or loudness of sounds.",
    active: false,
    theoryTitle: "Sound Intensity and Loudness",
    theoryDescription:
      "In the context of music, dynamics refers to the aspect of volume, or sound power and intensity. Roughly speaking, sound power defines how much energy (measured in Watt) per time unit is transferred from a sound source through air into all directions. Sound intensity then describes the amount of sound power per unit area.\nSince human perception of loudness is logarithmic, it makes sense to transfer sound power and intensity to a logarithmic scale too - this leads us to the decibel (dB) scale. A decibel level of 0 dB marks the threshold of hearing, while (already) at 130 dB the threshold of pain is reached. The perceived loudness of a normal conversation is somewhere in the middle, at around 60 dB.\nWhile sound intensity, as measured in dB, is an objective measure, a subjective, audio-conceptual correlate can be found in the concept of loudness (recall that this is similar to the relationship of frequency and pitch, introduced in an earlier module). Loudness is a subjective phenomenon for various reasons: not all sounds are perceived as equally loud by all individuals - factors such as age, hearing ability etc. play important roles. Furthermore, sounds that have the same intensity (in dB) but differ in frequency are also not perceived as equally loud.",
    theoryKeywords: [
      "dynamics",
      "loudness",
      "sound power",
      "sound intensity",
      "decibel",
    ],
    exampleTitle: "Looping through different levels of loudness",
    exampleCode:
      'const volumes = [0, 5, 10, 20, 30, 50];\nconst now = Tone.now();\n\nfor (let i = 0; i < volumes.length; i++) {\n\tlet osc = new Tone.Oscillator("A4", "sine");\n\tosc.volume.value = volumes[i];\n\tosc.toDestination().start(now + i);\n\tosc.stop(now + i + 0.5);\n}',
    exampleDescription: [
      "In this example, we are looping through different decibel values (make sure to adjust your speakers as it will get loud quite quickly!).",
      "Similar to the last example, we are using a for loop again. When we look at the three statements used to define the for loop, we can spot two differences compared to the last example: For the second statement, we make the condition dependent on the length of the volumes array. This approach to handling values or parameters is called softcoding (as opposed to hardcoding, which we did in the last module). The advantage of softcoding is that it is less error-prone since you only have to change your data source once: in our case, we can add or delete a value from the volumes array and the for loop will still be working fine without us having to manually change the condition.\n Now let's look at the third statement: here we have not changed the way the loop variable is handled in each iteration, but instead, we have just used a cleaner syntax: i++ is simply a shortcut version of i+=1.",

      // the first statemenet again defines the initial variable. The second statement defines the condition, which this time is dependent on the length of the volumes array. "
    ],
    exampleCodeVars: [],
    exampleCodeConcepts: [
      "looping through",
      "for loop",
      "second statement",
      "softcoding",
      "less error-prone",
      "third statement",
      "different syntax",
      "cleaner syntax",
    ],
    play: function () {
      const volumes = [0, 5, 10, 20, 30, 50];

      const now = Tone.now();
      for (let i = 0; i < volumes.length; i++) {
        let osc = new Tone.Oscillator("A4", "sine");
        osc.volume.value = volumes[i];
        osc.toDestination().start(now + i);
        osc.stop(now + i + 0.5);
      }
    },
    stop: function () {},
    tiyTitle: "",
    tiyInstructions: "",
    tiyKeywords: [],
  },

  // 5 - Timbre
  // {
  //   id: 5,
  //   topicId: "timbre",
  //   title: "Timbre",
  //   icon: <RiSoundModuleLine />,
  //   moduleColour: "#7c90f6",
  //   shortDescription:
  //     "This module will introduce you to the concept of timbre  - the components that make a sound unique.",
  //   active: false,
  //   theoryTitle: "Different data formats",
  //   theoryDescription: "Usually, ",
  //   theoryKeywords: [],
  //   exampleTitle: " === Use me as a template === ",
  //   exampleCode: "",
  //   exampleDescription: ["", ""],
  //   exampleCodeVars: [],
  //   exampleCodeConcepts: [],
  //   play: function () {},
  //   stop: function () {},
  //   tiyTitle: " === placeholder TIY title === ",
  //   tiyInstructions: " === placeholder instructions === ",
  //   tiyKeywords: [],
  // },

  //#####################################################################################################################
  // 6
  {
    id: 5,
    topicId: "template",
    title: "More to come!",
    icon: <FaLock />,
    moduleColour: "purple",
    shortDescription:
      "Use me as a template lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsumlorem ipsum lorem ipsum lorem ipsum...",
    active: false,
    theoryTitle: " === Use me as a template === ",
    theoryDescription: "Use me as a template",
    theoryKeywords: [],
    exampleTitle: " === Use me as a template === ",
    exampleCode: "",
    exampleDescription: " === Use me as a template === ",
    exampleCodeVars: [],
    exampleCodeConcepts: [],
    play: function () {},
    stop: function () {},
    tiyTitle: " === placeholder TIY title === ",
    tiyInstructions: " === placeholder instructions === ",
    tiyKeywords: [],
  },
];

// TODO: saved for later use:
// drums in a setup function
//  "let loopBeat;\nlet bassSynth;\nfunction setup() {\n\tbassSynth = new Tone.MembraneSynth().toDestination();\n\tloopBeat = new Tone.Loop(song, '4n');\n\tTone.Transport.start();\n\tloopBeat.start(0)\n}\n\nfunction song(time) {\n\tbassSynth.triggerAttackRelease('c1', '8n', time);\n}\n\nsetup();\n"

// TEMPLATE FOR LEARNING ITEM => TODO: at a later stage, discuss which properties to display in the overview page
// const LearningItem = {
//   id: Number,
//   title: String,
//   shortDiscription: String,
//   looked: true,
//   difficulty: 1,
//   endDate: Date,
//   startDate: Date,
//   process: 0,
//   meta: {
//     minutesWriting: 20,
//     minutesProcrastinating: 0,
//   },
// };

// EXAMPLE START
// // create a synth
// const synth = new Tone.MembraneSynth().toMaster();
// // create an array of notes to be played
// const notes = ["220", "440", "880"];
// // create a new sequence with the synth and notes
// const synthPart = new Tone.Sequence(
//   function (time, note) {
//     synth.triggerAttackRelease(note, "10hz", time);
//   },
//   notes,
//   "4n"
// );
// // Setup the synth to be ready to play on beat 1
// synthPart.start();
// // Note that if you pass a time into the start method
// // you can specify when the synth part starts
// // e.g. .start('8n') will start after 1 eighth note
// // start the transport which controls the main timeline
// Tone.Transport.start();
// EXAMPLE END

// EXAMPLE START
// const synth = new Tone.Synth().toDestination();
// // use an array of objects as long as the object has a "time" attribute
// const part = new Tone.Part(
//   (time, value) => {
//     // the value is an object which contains both the note and the velocity
//     synth.triggerAttackRelease(value.note, "8n", time, value.velocity);
//   },
//   [
//     { time: 0, note: "220", velocity: 0.9 },
//     { time: "0:2", note: "440", velocity: 0.9 },
//     { time: "0:2:8", note: "880", velocity: 0.9 },
//   ]
// ).start(0);
// Tone.Transport.start();
// EXAMPLE END

// TODO: format for integrating information ?
// <h5>Attack</h5>
//         <p>
//           The <b className="emusic">attack</b> of an envelope controls the
//           time it takes for the envelope to reach its peak at the start of a
//           note.
//         </p>
//         <h5>Decay</h5>
//         <p>
//           The <b className="emusic">decay</b> can be thought of as the
//           opposite of the attack, and determines how long it takes for the
//           envelope to decrease from its peak to the sustain level.
//         </p>
//         <h5>Sustain</h5>
//         <p>
//           The <b className="emusic">sustain</b> defines the level on which
//           the envelope will stay for the duration of the note.
//         </p>
//         <h5>Release</h5>
//         <p>
//           The <b className="emusic">release</b> defines the time it takes
//           for the envelope to reach its minimum level after the note is
//           released.
//         </p>
