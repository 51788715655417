import React from "react";
import { Image } from "react-bootstrap";
import Card from 'react-bootstrap/Card'
import finisher from "./../data/images/Shiny Happy Weird Poses.png";
import BadgeCard from "./BadgeCard";
import { learningContent } from "./../data/content/learning-content";
import { rewardContent } from "./../data/content/reward-content";

const Dashboard = () => {

  const student = (JSON.parse(window.sessionStorage.getItem('token')).student);
  var learningContentLength = Object.keys(learningContent).length;
  var studentUsername = student.username;

  var typeAward = "Award";
  var awardName7Days = "7 Days in a Row";
  const percentage7Days = student.sevenDaysAwardAmount * 10;

  var awardName30Days = "30 Days in a Row";
  const percentage30Days = student.thirtyDaysAwardAmount * 10;

  var typeLevel = "Level";
  var awardNameLevel = rewardContent[student.activeLevelId - 1].title;
  const percentageLevels = Math.round(100 / learningContentLength * student.activeLevelId);

  return (
    <>
      <div className="page-container">
        <main className="main">
          <h1>Dashboard</h1>
          <Card id="dashboard-welcome-message">
            <Card.Body>
              <h2>Hi {studentUsername}</h2>
              <h3>
                It's awesome you're back! Keep up the good work!
              </h3>
            </Card.Body>
          </Card>
          <div className="row w3-center" style={{ alignItems: "center", verticalAlign: "middle"}}>
            <Image
              src={finisher}
              style={{ width: 25 + "%", marginLeft: 5 + "%" }}
              rounded
            />
            <BadgeCard type={typeLevel} awardName={awardNameLevel} percentage={percentageLevels}></BadgeCard>
            <BadgeCard type={typeAward} awardName={awardName7Days} percentage={percentage7Days}></BadgeCard>
            <BadgeCard type={typeAward} awardName={awardName30Days} percentage={percentage30Days}></BadgeCard>
          </div>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
