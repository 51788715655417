// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PublicFooter from './PublicFooter';
import PublicHeader from './PublicHeader';
import ForgotPassword from './ForgotPassword';
import SignUp from './SignUp';
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from 'react-ga';

import 'react-toastify/dist/ReactToastify.css';

const baseUrl = 'https://muco.fh-campuswien.ac.at/api/public';

async function loginUser(credentials) {
    return fetch(baseUrl + '/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then((response) => {
            if (response.status >= 400 && response.status < 600) {
                throw new Error("Bad Request: While trying to log in!");
            }
            return response;
        })
        .then(data => data.json())
        .catch((error) => {
            // Your error is here!
            console.log("In fetch" + error);

        });
}

export default function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            const token = await loginUser({
                username,
                password
            });
            setToken(token);

            ReactGA.event({
                category: 'Login',
                action: 'Login',
                label: 'success'
            });


        } catch (err) {
            console.log("In login function" + err);

            ReactGA.event({
                category: 'Login',
                action: 'Login',
                label: 'fail'
            });
            toast.error('Error: Combination of Username & Password unknown!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <div id="login">
            <PublicHeader></PublicHeader>
            <br></br>
            <ToastContainer />
            <div className="login-wrapper card body ">
                <h2>Welcome Back!</h2>
                <form>
                    <div id="login-inputs">
                        <label>
                            Username<br></br>
                            <input className="w3-input w3-border w3-round-large w3-center" type="text" onChange={e => setUserName(e.target.value)} required />
                        </label>
                        <br></br>
                        <label>
                            Password<br></br>
                            <input className="w3-input w3-border w3-round-large w3-center" type="password" onChange={e => setPassword(e.target.value)} min="6" required />
                        </label>
                        {/* No Backend yet therefore commented - <ForgotPassword></ForgotPassword> */}
                        <br></br>
                        <br></br>
                    </div>
                    <div>
                        <button className="w3-btn w3-round-xxlarge w3-white w3-border w3-center" onClick={handleSubmit} id="login-button" type="submit">Get inside!</button>
                    </div>
                    <SignUp></SignUp>
                    <p></p>
                </form>
            </div>
            <PublicFooter></PublicFooter>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};