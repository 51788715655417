import React from 'react';
import { Image } from "react-bootstrap";
import dots from "./../data/images/Group 1182.png";
import why from "./../data/images/Why combine Coding with Music.png";
import principles from "./../data/images/Principles of Learning.png";
import terms from "./../data/images/Privacy & Terms.png";
import hui from "./../data/images/huispaty 2020.png";
import explore from "./../data/images/Explore your creative side by composing in code.png";

export default function PublicFooter() {

    return (
        <div className="footer login-footer">
            <div className="w3-col m3 w3-center">
                <Image
                    src={dots}
                    style={{ }}
                    rounded
                />
            </div>
            <div className="w3-col m3 w3-left">
                <Image
                    src={why}
                    style={{}}
                />
                <br></br>
                <Image
                    src={principles}
                    style={{}}
                />
                <br></br>
                <Image
                    src={terms}
                    style={{}}
                />
                <br></br>
                <Image
                    src={hui}
                    style={{}}
                />
            </div>
            <div >
            <Image
            className="w3-col m3 w3-right w3-margin w3-display-middleright"
                    src={explore}
                />
            </div>
        </div>
    );
}